import { combineEpics, Epic } from "redux-observable";
import { of } from "rxjs";
import { catchError, filter, map, takeUntil, switchMap } from "rxjs/operators";
import { ActionType, createAsyncAction, createReducer, getType, isActionOf } from "typesafe-actions";
import { company } from "../../models/base";
import { IListOfTeams } from "../../models/list-of-teams-of-a-company";
import { RootState } from "../ducks";
import { RootAction } from "./types";
import * as Providers from '../../providers'
import { ITeam } from "../../models/team";

interface StateOfListOfTeamsOfCompany {
  // 代表查詢一間公司底下的資料, 或者目前畫面處於哪個公司
  error: Error | undefined | null
  company_name: string // 公司名稱
  teams: Partial<ITeam>[] // 公司底下的所有團隊
}

/**
 * 這個檔案用途是取得清單，關於公司內的所有團隊
 * Notice: 有設定相同公司名稱的團隊，會當作分類在同一個公司底下
 */
export const fetchListOfTeamsOfCompanyPub = createAsyncAction(
  'FETCH_LIST_OF_TEAMS_OF_COMPANY_PUB_REQUEST',
  'FETCH_LIST_OF_TEAMS_OF_COMPANY_PUB_SUCCESS',
  'FETCH_LIST_OF_TEAMS_OF_COMPANY_PUB_FAILURE',
  'FETCH_LIST_OF_TEAMS_OF_COMPANY_PUB_CANCEL',
)<
  company,
  IListOfTeams,
  Error,
  undefined
>()

const initialState: StateOfListOfTeamsOfCompany = {
  error: undefined,
  company_name: '',
  teams: []
}

export const fetchListOfTeamsOfCompanyPubEpic: Epic<
  RootAction,
  RootAction,
  RootState,
  typeof Providers
> = (action$, state$, { listOfCompanyTeamsPubAPI }) =>
  action$.pipe(
    filter(isActionOf(fetchListOfTeamsOfCompanyPub.request)),
    switchMap(action => 
      listOfCompanyTeamsPubAPI
        .post({company: action.payload})
        .pipe(
          map(data => fetchListOfTeamsOfCompanyPub.success(data.response)),
          catchError((message: Error) => 
            of(fetchListOfTeamsOfCompanyPub.failure(message))
          ),
          takeUntil(action$.pipe(
            filter(isActionOf(fetchListOfTeamsOfCompanyPub.cancel))
            )
          )
        )
      )
  )

export type ListOfTeamsOfCompanyPubAction = ActionType<typeof fetchListOfTeamsOfCompanyPub>

export const listOfTeamsOfCompanyPubReducer = createReducer(initialState)
  .handleAction(
    getType(fetchListOfTeamsOfCompanyPub.request),
    (state: StateOfListOfTeamsOfCompany, { payload }: any) => ({
      ...state,
      company_name: payload,
    })
  )
  .handleAction(
    getType(fetchListOfTeamsOfCompanyPub.success),
    (state: StateOfListOfTeamsOfCompany, { payload }: any) => ({
      ...state,
      ...payload,
      error: null,
    })
  )
  .handleAction(
    getType(fetchListOfTeamsOfCompanyPub.failure),
    (state: StateOfListOfTeamsOfCompany, { payload }: any) => ({
      ...state,
      error: payload,
    })
  )
  .handleAction(
    getType(fetchListOfTeamsOfCompanyPub.failure),
    (state: StateOfListOfTeamsOfCompany, { payload }: any) => ({
      ...state,
      error: null,
    })
  )

/**
 * Notice: listOfTeamsOfCompanyPubEpic 會讓 src/store/epics/index.ts 導入，
 * 作為全系統範圍適用的 redux store 元件
 */
const listOfTeamsOfCompanyPubEpic = combineEpics<any>(fetchListOfTeamsOfCompanyPubEpic)
export default listOfTeamsOfCompanyPubEpic