import i18n from 'i18next'
import LanguageDetector from 'i18next-browser-languagedetector'
import { initReactI18next } from 'react-i18next'
import en from './assets/i18n/en.json'
import zh from './assets/i18n/zh.json'

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .use(LanguageDetector)
  .init({
    debug: false,
    interpolation: { escapeValue: false }, // react already safes from xss
    lng: 'zh', // language to use
    resources: {
      en: {
        translation: en, // 'common' is our custom namespace
      },
      zh: {
        translation: zh,
      },
    },
    keySeparator: false, // we do not use keys in form messages.welcome
  })

export default i18n
