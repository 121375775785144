import {
  IonBadge,
  IonButton,
  IonButtons,
  IonChip,
  IonContent,
  IonFab,
  IonHeader,
  IonIcon,
  IonLabel,
  IonPage,
  IonSpinner,
  IonTitle,
  IonToolbar,
} from '@ionic/react'
import useComponentSize from '@rehooks/component-size'
import { close } from 'ionicons/icons'
import React, { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import ReactPannellum from 'react-pannellum'
import { ISuiquiFile } from '../models/suiquiFile'
import './PanoramaViewerModal.scss'

const PanoramaViewerModal: React.FC<any> = ({
  isOpen,
  dismiss,
  item,
  items,
  index,
}: {
  isOpen: boolean
  dismiss: any
  item: ISuiquiFile
  items: ISuiquiFile[]
  index: number
}) => {
  const { t } = useTranslation()
  const contentRef = useRef(null)
  const pageRef = useRef(null)
  const slidesRef = useRef(null)
  const { width, height } = useComponentSize(contentRef)

  const [isPageLoading, setIsPageLoading] = useState(true)

  const cancel = () => {}

  useEffect(() => {
    if (!isOpen) return

    setTimeout(() => {
      // Workaround ...
      // @ts-ignore
      pageRef.current?.classList.remove('ion-page-invisible')
    }, 300)

    if (item) {
      setTimeout(() => {
        setIsPageLoading(false)
        /*
        if (index > 0) {
          // @ts-ignore
          slidesRef.current?.slideTo(index)
        }*/
      }, 1000)
    }
  }, [isOpen, item, index])

  return (
    <IonPage className="page-panorama-viewer-modal" ref={pageRef}>
      <IonHeader>
        <IonToolbar color="primary">
          <IonTitle>
            <IonButton class="logo immutable">{t('Suiqui Resume')}</IonButton>
            <span className="subtitle">{t('360° Viewer')}</span>
          </IonTitle>
          <IonButtons slot="end">
            <IonButton
              strong
              fill="clear"
              slot="icon-only"
              onClick={() => dismiss(cancel)}
            >
              <IonIcon size="large" icon={close}></IonIcon>
            </IonButton>
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonContent ref={contentRef} scrollEvents={false}>
        {isPageLoading ? (
          <div className="ion-text-center centered">
            <IonSpinner color="light" name="crescent"></IonSpinner>
          </div>
        ) : (
          <>
            {item?.url && (
              <ReactPannellum
                id="1"
                sceneId={item.key}
                imageSource={`${item.url}=h2048`}
                style={{
                  width: '100%',
                  height: 'calc(100vh - 56px)',
                  background: '#000000',
                }}
                config={{
                  type: 'equirectangular',
                  crossOrigin: 'anonymous',
                  autoLoad: true,
                }}
              />
            )}
          </>
        )}
      </IonContent>

      <IonFab vertical="bottom" horizontal="end" slot="fixed">
        <div className="ion-text-right">
          <IonBadge color="dark" className="filename">
            {item.name}
          </IonBadge>
        </div>
        {item.user && (
          <div>
            <IonChip color="dark immutable">
              <IonLabel>
                {t('Upload by=')}
                {item.user.displayname}
              </IonLabel>
            </IonChip>
          </div>
        )}
      </IonFab>
    </IonPage>
  )
}

export default PanoramaViewerModal
