import { IonAlert, IonRouterOutlet, IonToast } from '@ionic/react'
import 'moment/locale/zh-tw'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { Redirect, Route } from 'react-router'
import { PREFIX_COMPANY_URL, PREFIX_TEAM_URL } from './helpers/contants'
import { isIOS, isLineInApp } from './helpers/util'
import { useClientStorage } from './hooks/use-clientstorage'
import Checklist from './pages/Checklist'
import CompanyPage from './pages/Company/Company'
import Home from './pages/Home'
import V2Checklist from './pages/V2Checklist/V2Checklist'

const AppRoutes: React.FC = props => {
  const { t } = useTranslation()
  const [token, setToken, isTokenLoading] = useClientStorage('apiToken')
  const [currentUser, setCurrentUser] = useClientStorage('suiquiUserData')
  const [lang, setLang] = useClientStorage('userLang')
  const auth = useSelector((state: any) => state.auth)
  const user = useSelector((state: any) => state.user)
  const authDuck = useSelector((state: any) => state.authDuck)
  const [isAuthed, setIsAuthed] = useState(true)
  const [showUpdateAvailableToast, setShowUpdateAvailableToast] = useState(
    false
  )
  const [showErrorAlert, setShowErrorAlert] = useState(false)
  const [errorStatus, setErrorStatus] = useState()
  const dispatch = useDispatch()

  const showReload = (swregWaiting?: ServiceWorker) => {
    if (isIOS() || isLineInApp()) {
      if (swregWaiting) {
        swregWaiting.postMessage({ type: 'SKIP_WAITING' })
      }
      // Workaround: disable on iOS ...
      return
    }

    if (isIOS() || isLineInApp()) {
      // Workaround for iOS ...
      if (swregWaiting) {
        swregWaiting.postMessage({ type: 'SKIP_WAITING' })
      }
      if ('serviceWorker' in navigator) {
        navigator.serviceWorker
          .getRegistrations()
          .then(registrations =>
            Promise.all(registrations.map(r => r.unregister()))
          )
          .then(() => window.location.reload())
      }
    } else {
      if (swregWaiting) {
        swregWaiting.postMessage({ type: 'SKIP_WAITING' })
      }
      window.location.reload()
    }
  }

  if ('BroadcastChannel' in window) {
    const updateChannel = new BroadcastChannel('app-update')
    updateChannel.addEventListener('message', async event => {
      if (navigator.serviceWorker) {
        const swreg = await navigator.serviceWorker.getRegistration()
        if (swreg && swreg.waiting) {
          //setShowUpdateAvailableToast(true)
          showReload(swreg.waiting)
        }
      }
    })
  } else {
    window.addEventListener('message', async event => {
      //console.info('window.message', event, window.location)
      if (event.origin !== window.location.origin) return
      if (!event?.data?.channelName) return

      if (event.data.channelName === 'app-update') {
        if (navigator.serviceWorker) {
          const swreg = await navigator.serviceWorker.getRegistration()
          if (swreg && swreg.waiting) {
            //setShowUpdateAvailableToast(true)
            showReload(swreg.waiting)
          }
        }
      }
    })
  }

  return (
    <>
      <IonRouterOutlet animated={false} {...props}>
        <Route exact path="/home" component={Home} />
        <Route exact path={[`/${PREFIX_COMPANY_URL}/:name`, `/${PREFIX_COMPANY_URL}`]} component={CompanyPage}/>
        <Route
          exact
          path={[
            `/${PREFIX_COMPANY_URL}/:company_name/${PREFIX_TEAM_URL}/:team_key`,
            `/${PREFIX_COMPANY_URL}/:company_name/${PREFIX_TEAM_URL}/:team_key/checklist/:key`,
            `/${PREFIX_TEAM_URL}/:team_key/checklist/:key`,
            `/${PREFIX_TEAM_URL}/:team_key`
          ]}
          component={V2Checklist}
        />
        {/* V2版本的網頁路徑與處理的元件 */}

        <Route
          exact
          path={['/checklist/:key', '/cl/:key', '/c/:key']}
          component={Checklist}
        />
        <Route exact path={['/checklist', '/cl', '/c']} component={Checklist} />
        <Redirect exact from="/" to="/home" />
      </IonRouterOutlet>
      <IonAlert
        keyboardClose={true}
        isOpen={showErrorAlert}
        onDidDismiss={() => setShowErrorAlert(false)}
        header={t('Oops...')}
        message={t(
          'Something has gone wrong. Please contact a system administrator.（{{status}}',
          {
            status: errorStatus,
          }
        )}
        buttons={[
          {
            text: t('OK'),
            cssClass: 'ok',
            handler: params => {},
          },
        ]}
      />
      {showUpdateAvailableToast && (
        <IonToast
          isOpen={showUpdateAvailableToast}
          onDidDismiss={() => setShowUpdateAvailableToast(false)}
          message={t('Please reload the app to continue.')}
          position="bottom"
          cssClass="update-available"
          buttons={[
            {
              text: t('Click to Update app'),
              role: 'cancel',
              handler: () => {
                showReload()
              },
            },
          ]}
        />
      )}
    </>
  )
}

export default AppRoutes
