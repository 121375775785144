import { applyMiddleware, createStore } from 'redux'
import { composeWithDevTools } from 'redux-devtools-extension'
import { createEpicMiddleware } from 'redux-observable'
import * as dependencies from '../providers'
import reducers from './ducks'
import epics from './epics'

const epicMiddleware = createEpicMiddleware({
  dependencies,
})

const store = createStore(
  reducers,
  composeWithDevTools(
    applyMiddleware(epicMiddleware, store => next => action => {
      try {
        next(action)
      } catch (e) {
        setTimeout(() => {
          throw e
        })
      }
    })
  )
)

epicMiddleware.run(epics)

export default store
