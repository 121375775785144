import { ajax } from 'rxjs/ajax'
import { API } from '../helpers/base'
import { company } from '../models/base'

/**
 * 查詢一個公司的所有團隊列表的 API
 * Notice: 有設定相同公司名稱的團隊，會當作分類在同一個公司底下
 */
export const post = ({company}: {company: company}) => {
  return ajax({
    url: API('company/teams', 'AUTH_PUB'),
    method: 'POST',
    responseType: 'json',
    headers: {
      'Content-Type': 'application/json',
    },
    body: {
      company,
    },
  })
}