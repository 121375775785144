import {
  IonBadge,
  IonButton,
  IonButtons,
  IonChip,
  IonContent,
  IonFab,
  IonFabButton,
  IonHeader,
  IonIcon,
  IonImg,
  IonLabel,
  IonPage,
  IonSlide,
  IonSlides,
  IonSpinner,
  IonTitle,
  IonToolbar,
} from '@ionic/react'
import useComponentSize from '@rehooks/component-size'
import { arrowBack, arrowForward, close } from 'ionicons/icons'
import React, { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { getImageUrl } from '../helpers/file'
import { ISuiquiFile } from '../models/suiquiFile'
import './PhotoViewerModal.scss'

const PhotoViewerModal: React.FC<any> = ({
  isOpen,
  dismiss,
  item,
  items,
  index,
}: {
  isOpen: boolean
  dismiss: any
  item: ISuiquiFile
  items: ISuiquiFile[]
  index: number
}) => {
  const { t } = useTranslation()
  const contentRef = useRef(null)
  const pageRef = useRef(null)
  const slidesRef = useRef(null)
  const { width } = useComponentSize(contentRef)
  const [currentIndex, setCurrentIndex] = useState(0)
  const [isPageLoading, setIsPageLoading] = useState(true)

  const cancel = () => {}

  const slideOpts = {
    initialSlide: index || 0,
    centeredSlides: true,
    paginationType: 'progress',
    speed: 200,
    grabCursor: true,
    cubeEffect: {
      shadow: true,
      slideShadows: true,
      shadowOffset: 20,
      shadowScale: 0.94,
    },
    keyboard: true,
  }

  useEffect(() => {
    if (!isOpen) return

    setTimeout(() => {
      // Workaround ...
      // @ts-ignore
      pageRef.current?.classList.remove('ion-page-invisible')
    }, 300)

    if (item) {
      setTimeout(() => {
        setIsPageLoading(false)
        /*
        if (index > 0) {
          // @ts-ignore
          slidesRef.current?.slideTo(index)
        }*/
      }, 1000)
    }
  }, [isOpen, item, index])

  const prev = async () => {
    // @ts-ignore
    contentRef.current?.scrollToTop()
    // @ts-ignore
    const index = (await slidesRef.current?.getActiveIndex()) - 1
    if (index < items.length) {
      // @ts-ignore
      await slidesRef.current?.slideTo(index)
    }
  }

  const next = async () => {
    // @ts-ignore
    contentRef.current?.scrollToTop()
    // @ts-ignore
    const index = (await slidesRef.current?.getActiveIndex()) + 1
    if (index < items.length) {
      // @ts-ignore
      await slidesRef.current?.slideTo(index)
    }
  }

  const onSlideWillChange = async () => {
    // @ts-ignore
    setCurrentIndex(await slidesRef.current?.getActiveIndex())
  }

  return (
    <IonPage className="page-photo-viewer-modal" ref={pageRef}>
      <IonHeader>
        <IonToolbar color="primary">
          <IonTitle>
            <IonButton class="logo immutable">{t('Suiqui Resume')}</IonButton>
            <span>{t('Photo Viewer')}</span>
          </IonTitle>
          <IonButtons slot="end">
            <IonButton
              strong
              fill="clear"
              slot="icon-only"
              onClick={() => dismiss(cancel)}
            >
              <IonIcon size="large" icon={close}></IonIcon>
            </IonButton>
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonContent ref={contentRef} scrollEvents={true}>
        {isPageLoading ? (
          <div className="ion-text-center centered">
            <IonSpinner color="light" name="crescent"></IonSpinner>
          </div>
        ) : (
          <>
            <IonFab
              vertical="center"
              horizontal="start"
              slot="fixed"
              hidden={currentIndex === 0}
            >
              <IonFabButton className="prev" onClick={prev}>
                <IonIcon icon={arrowBack} />
              </IonFabButton>
            </IonFab>
            <IonFab
              vertical="center"
              horizontal="end"
              slot="fixed"
              hidden={currentIndex >= items?.length - 1}
            >
              <IonFabButton className="next" onClick={next}>
                <IonIcon icon={arrowForward} />
              </IonFabButton>
            </IonFab>
            <IonSlides
              ref={slidesRef}
              pager={false}
              scrollbar={true}
              options={slideOpts}
              onIonSlideWillChange={() => onSlideWillChange()}
            >
              {items?.map((item, index) => {
                return (
                  <IonSlide key={index}>
                    <IonBadge color="success" className="count">{`${index +
                      1}`}</IonBadge>
                    {item?.memo && (
                      <IonChip color="light" className="memo immutable">
                        <div>{item.memo}</div>
                      </IonChip>
                    )}
                    <div>
                      <IonImg
                        src={`${getImageUrl(item, width, `w${width}`)}`}
                      />
                      <div className="ion-padding-top">
                        <div>
                          <IonBadge color="dark" className="filename">
                            {item.name}
                          </IonBadge>
                        </div>
                        {item.user && (
                          <div>
                            <IonChip color="warning immutable">
                              <IonLabel>
                                {t('Upload by=')}
                                {item.user?.displayname}
                              </IonLabel>
                            </IonChip>
                          </div>
                        )}
                      </div>
                    </div>
                  </IonSlide>
                )
              })}
            </IonSlides>
          </>
        )}
      </IonContent>
    </IonPage>
  )
}

export default PhotoViewerModal
