import { IonButton, IonCard, IonCardContent, IonCardHeader, IonCardTitle, IonContent, IonFab, IonFabButton, IonHeader, IonIcon, IonPage, IonText, IonTitle, IonToolbar } from "@ionic/react";
import { refresh } from 'ionicons/icons';
import React, { useCallback, useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { ITeam } from "../../models/team";
import { fetchListOfTeamsOfCompanyPub } from "../../store/epics/list-of-teams-of-a-company-pub";
import { ReactRouterAction } from "../../store/epics/types";
import './Company.scss';
import imagePlaceholder from '../../assets/img/image-placeholder.png';
import { useHistory } from "react-router";
import { PREFIX_COMPANY_URL, PREFIX_TEAM_URL } from "../../helpers/contants";

const CompanyPage: React.FC<ReactRouterAction> = ({ match }) => {

  const { t } = useTranslation();
  const dispatch = useDispatch();
  const contentRef = useRef(null);
  const listOfTeamsOfCompanyPub = useSelector((state: any) => state.listOfTeamsOfCompanyPub)

  // 查詢後端 API 後取得的團隊列表資料
  const teams = listOfTeamsOfCompanyPub.teams;

  // 查詢後端 API 用的公司名稱
  const companyName = listOfTeamsOfCompanyPub.company_name;

  const history = useHistory();

  const doRefresh = useCallback(() => {
    // 重新整理畫面後，取得公司的所有團隊

    dispatch(fetchListOfTeamsOfCompanyPub.cancel());
    if (match.params.name?.trim().length > 0) {
      /**
       * 如果網址參數 name 去除 whitespace 後的長度大於 0，
       * 代表有客戶名稱，
       * 則向後端查詢資料
       */
      dispatch(fetchListOfTeamsOfCompanyPub.request(match.params.name.trim()));
    } else {
      /**
       * 如果沒有公司名稱，則用空白去查詢
       */
      dispatch(fetchListOfTeamsOfCompanyPub.request(''));
    }

  }, [match.params?.name])

  useEffect(() => {
    // 此useEffect 專門：取得公司的所有團隊

    doRefresh();
  }, [doRefresh])

  return (
    <IonPage className="page-company">
      <IonHeader className="no-border">
        <IonToolbar color="primary">
          <IonTitle>
            <IonButton className="logo immutable">
              <span>Suiqui</span>
            </IonButton>
            <span>{t('Resume')}</span>
          </IonTitle>
        </IonToolbar>
      </IonHeader>

      <IonContent
        ref={contentRef}
        scrollEvents={true}
        className="content-section"
      >
        <IonFab vertical="bottom" horizontal="end" slot="fixed">
          {/* 重新整理畫面的按鈕 */}
          <IonFabButton
            onClick={doRefresh}>
            <IonIcon icon={refresh}></IonIcon>
          </IonFabButton>
        </IonFab>

        <div className="section-company">
          <IonText className="company-name ion-text-center">
            {/* 公司名稱 */}
            <h2>{companyName}</h2>
          </IonText>
        </div>

        <div className="section-team">

          {/** 沒有團隊時顯示文字 */}
          {!teams?.length &&
            <IonText color="danger" className="ion-text-center">
              <h2>{t('No team')}, {t('Please set at least one team to company')}</h2>
            </IonText>
          }

          {
            teams?.length > 0 && teams.map((elem: ITeam, idx: number) => {
              return (
                <IonCard className="team" key={idx} onClick={() => history.push(`/${PREFIX_COMPANY_URL}/${match.params?.name}/${PREFIX_TEAM_URL}/${elem.key}`)}>
                  <IonCardHeader>
                    {
                      elem.team_info?.display_photo?.url ?
                        <img src={`${elem.team_info.display_photo.url}=w300`}
                        srcSet={`${elem.team_info.display_photo.url}=w150 150w,
                                ${elem.team_info.display_photo.url}=w250 250w,
                                ${elem.team_info.display_photo.url}=w300 300w`}
                          sizes={`(max-width: 576px) 150px,
                                                (max-width: 768px) 250px,
                                                300px`} />
                        :
                        /**
                         * 沒有團隊圖片時，顯示預設圖片
                         */
                        <img src={`${imagePlaceholder}`} />
                    }
                  </IonCardHeader>

                  <IonCardContent>
                    <IonCardTitle className="team-name">
                      {/* 顯示團隊的公開用名稱，如果沒有此欄位，則使用提示文字 */}
                      {
                        elem.display_name ?? `(${t('Team does not set a public name')})`
                      }
                    </IonCardTitle>
                  </IonCardContent>

                </IonCard>
              )

            })
          }
        </div>
      </IonContent>
    </IonPage>
  )
}

export default CompanyPage;