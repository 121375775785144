import { ajax } from 'rxjs/ajax'
import { API } from '../helpers/base'
import { IListOfChecklist } from '../models/list-of-checklist'
/**
 * 取得團隊內的公開檢核表instance 列表 API
 * @param param0 
 * @returns 
 */
export const get = ({ params }: { params: Partial<IListOfChecklist> }) =>
  ajax({
    url: API('checklist/list', 'CHECKLIST_PUB'),
    method: 'POST',
    responseType: 'json',
    headers: {
      'Content-Type': 'application/json',
    },
    body: {
      ...params,
    },
  })
