import { RefresherEventDetail, RadioGroupChangeEventDetail } from '@ionic/core'
import {
  IonButton,
  IonButtons,
  IonCard,
  IonCardHeader,
  IonCardTitle,
  IonChip,
  IonCol,
  IonContent,
  IonFooter,
  IonGrid,
  IonHeader,
  IonIcon,
  IonItem,
  IonLabel,
  IonList,
  IonListHeader,
  IonLoading,
  IonModal,
  IonPage,
  IonRefresher,
  IonRefresherContent,
  IonRow,
  IonSelect,
  IonSelectOption,
  IonSpinner,
  IonText,
  IonTitle,
  IonToast,
  IonToolbar,
  isPlatform,
  useIonViewWillLeave,
} from '@ionic/react'
import useComponentSize from '@rehooks/component-size'
import classNames from 'classnames'
import {
  addCircleOutline,
  arrowBackOutline,
  caretUp,
  qrCode,
  removeCircleOutline,
  share,
} from 'ionicons/icons'
import QRCode from 'qrcode.react'
import React, { useCallback, useEffect, useRef, useState } from 'react'
import CopyToClipboard from 'react-copy-to-clipboard'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { Carousel } from 'react-responsive-carousel'
import 'react-responsive-carousel/lib/styles/carousel.min.css'
import { useHistory } from 'react-router-dom'
import { PREFIX_COMPANY_URL, PREFIX_TEAM_URL } from '../../helpers/contants'
import PanoramaViewerModal from '../../modals/PanoramaViewerModal'
import PhotoViewerModal from '../../modals/PhotoViewerModal'
import { IChecklist } from '../../models/checklist'
import { ISuiquiFile } from '../../models/suiquiFile'
import { fetchChecklistPub, resetChecklistPub } from '../../store/epics/checklist-pub'
import { fetchListOfChecklistPub, resetListOfChecklistPub } from '../../store/epics/list-of-checklist-pub'
import { ReactRouterAction } from '../../store/epics/types'
import './V2Checklist.scss'
/**
 * 畫面包含團隊的公開檢核表instance 清單，可以點選清單中內的instance，網址與畫面跟著改變內容
 */
const V2Checklist: React.FC<ReactRouterAction> = ({ match }) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const history = useHistory() // 取得 react-router-dom 的 history for navigating links
  const contentRef = useRef(null)
  const checklistPub = useSelector((state: any) => state.checklistPub)
  const listOfChecklistPub = useSelector((state: any) => state.listOfChecklistPub)
  const [isPageLoading, setIsPageLoading] = useState(false)
  const [showLoading, setShowLoading] = useState(false)
  const [currentItem, setCurrentItem] = useState()
  const [currentItems, setCurrentItems] = useState()
  const [currentIndex, setCurrentIndex] = useState()
  const [showPhotoViewerModal, setShowPhotoViewerModal] = useState(false)
  const [showPanoramaViewerModal, setShowPanoramaViewerModal] = useState(false)
  const [showQRCode, setShowQRCode] = useState(false)
  const [showCopyUrlToast, setShowCopyUrlToast] = useState(false)
  const [zoomLevel, setZoomLevel] = useState(0)
  const { width, height } = useComponentSize(contentRef)
  const [isGobackToCompanyPage, setIsGobackToCompanyPage] = useState<boolean>(false) // 回到公司的團隊列表頁

  /**
   * 存放下拉選單的陣列
   */
   const [listOfChecklist, setListOfChecklist] = useState<Array<IChecklist>>(listOfChecklistPub.checklists);

  /**
   * 存放團隊標籤的陣列
   */
  const tagList = listOfChecklistPub.team?.resume_tags

  /**
   * 使用者選到的檢核表instance
   */
  const [selectedTable, setSelectedTable] = useState<string>()

  /**
   * 下拉選單：選擇履歷 的 popover 客製化控制項 
   */
  const popOverConfigOptions = {
    cssClass: `options-of-checklist`,
  };

  /**
   * 使用者點選的團隊標籤陣列
   */
  const [listOfSelectedTag, setListOfSelectedTag] = useState<Array<string>>([]);

  useEffect(() => {
    /**
     * 功能：
     * 查詢團隊內的公開 checklist instance 清單列表
     */

    // 如果要返回上一頁，則不進行後續的查詢
    if (isGobackToCompanyPage) return

    // 取得團隊內的公開 checklist instance 清單列表
    if (!match.params.team_key) return

    dispatch(
      fetchListOfChecklistPub.request({
        team_key: match.params.team_key,
      })
    )
  }, [dispatch, match.params.team_key, isGobackToCompanyPage])

  useEffect(() => {
    if (checklistPub.key || listOfChecklistPub.team_key) {
      setShowLoading(false)
      setIsPageLoading(false)
    }
  }, [checklistPub.key, listOfChecklistPub.team_key])

  useEffect(() => {
    /**
     * 主要功能：
     * 當 listOfSelectedTag 改變時，
     * 重新產生下拉選單的變數: listOfChecklist 
     * Notice: 依照標籤分區，填入表格instance，例如：
     * 標籤1
     * instance1
     * instance2
     * instance3
     * 標籤2
     * instance4
     * 
     * Notice: 有處理一些例外情況:
     * 1. 若團隊有設定標籤, 但有一些公開表格instance是沒有綁定標籤的 => 額外放在一個標籤:(未設定標籤)底下
     * 2. 若團隊沒設定標籤, 下拉選單仍然要有表格instance列表
     */

    const tmpListOfChecklists: unknown[] = [];

    if (listOfSelectedTag.length > 0) {
      listOfSelectedTag.forEach((tag: string) => {
        tmpListOfChecklists.push({
          name: tag,
          isSelectable: false,
        })
  
        listOfChecklistPub.checklists?.forEach((checklist: IChecklist) => {
          if (checklist.tags?.includes(tag)) {
            tmpListOfChecklists.push({
              ...checklist,
              isSelectable: true,
            });
          }
        })
  
      })

    } else if (!listOfSelectedTag.length && tagList?.length > 0) {
      /**
       * 若使用者沒有選擇任何標籤，且團隊有設定標籤，
       * 則使下拉選單的變數: listOfChecklist ，填入所有表格instance。
       * Notice1: 仍然依照標籤分區，列出表格instance。
       * Notice2: 若表格instance未設定標籤，產生一區是:(未設定標籤)
       */
      tagList?.forEach((tag: string) => {
        tmpListOfChecklists.push({
          name: tag,
          isSelectable: false,
        })
  
        listOfChecklistPub.checklists?.forEach((checklist: IChecklist) => {
          if (checklist.tags?.includes(tag)) {
            tmpListOfChecklists.push({
              ...checklist,
              isSelectable: true,
            });
          }
        })
  
      });
      
      let countForNonAdded = 0; // 用以計算在上面未加入下拉選單的表格instance

      /**
       * 將尚未加入到下拉選單的公開表格instance，加入到下拉選單，並且是放在這區: (未設定標籤)
       */
      listOfChecklistPub.checklists?.forEach((element: IChecklist) => {
        const found = tmpListOfChecklists.find((item: unknown ) => (item as IChecklist).name === element.name)

        if (!found) {
          if (countForNonAdded === 0) {
            /**
             * 增加一筆資料是標籤: (未設定標籤)
             * Notice: 此標籤不能在下拉選單選擇
             */
            tmpListOfChecklists.push({
              name: `(${t('Non-tagged area')})`,
              isSelectable: false,
            })
          }

          countForNonAdded++;
          tmpListOfChecklists.push({
            ...element,
            isSelectable: true,
          })
        }
      });

    } else {
      /**
       * 如果團隊沒有設定標籤，下拉選單預設顯示表格instance 列表
       * 且不提供分類標籤
       */

      listOfChecklistPub.checklists?.forEach((element: IChecklist) => {
        tmpListOfChecklists.push({
          ...element,
          isSelectable: true,
        })
      })
    }

    /**
     * Notice: 強制轉型，雖然有些資料沒有符合 IChecklist
     */
    setListOfChecklist(tmpListOfChecklists as IChecklist[]);

  }, [listOfSelectedTag, listOfChecklistPub.checklists, tagList])

  /**
   * 當改變下拉選單中已選擇的表格 instance，
   * 則:
   * 1. 使下拉選單選擇值改變
   * 2. 前往特定已選擇表格instance的網址(仍在建築履歷產品畫面)
   */
   const onChangeSelectedChecklist = (e: CustomEvent<RadioGroupChangeEventDetail>) => {
    setSelectedTable(e.detail.value)
    if (match.params.company_name) {
      /**
       * 如果網址有公司名稱，
       * 則依照當前網址特徵，前往含有公司名稱的表格instance畫面
       * 
       */
      history.push(`/${PREFIX_COMPANY_URL}/${match.params.company_name}/${PREFIX_TEAM_URL}/${match.params.team_key}/checklist/${e.detail.value}`)
    } else {
      /**
       * 如果網址沒有公司名稱，
       * 則依照當前網址特徵，前往沒有公司名稱的表格instance畫面
       * 
       */
      history.push(`/${PREFIX_TEAM_URL}/${match.params.team_key}/checklist/${e.detail.value}`)
    }
    
  }

  useEffect(() => {
    /**
     * Notice:
     * 此個 useEffect 的用途是
     * 1. 設定下拉選單的已選擇項目
     * 2. 向後端 API 查詢指定表格instance資料
     */

    /** 
     * 當要返回到公司的團隊列表頁，
     * 則不繼續後續行為
     */
    if (isGobackToCompanyPage) return ;

    setIsPageLoading(true)

    if (!match.params.key && listOfChecklist?.length > 0) {

      // 找出第一個可以選擇(isSelectable === true)的表格instance
      const firstSelectableChecklist = listOfChecklist.find(elem => {
        return elem.isSelectable === true
      })

      if (firstSelectableChecklist) {
        dispatch(
          fetchChecklistPub.request({
            key: firstSelectableChecklist.key
          })
        )

        /**
         * 此區域是處理下拉選單的選項是已選擇狀態，
         * 以及
         * 是否在 url 沒有 match.params.key 值的情境下，取代當前網址，令其增加 checklist/:key 
         * 
         * Notice: 不使用 onChangeSelectedChecklist() 是因為此處需要使用 history.replace()
         * start
         */

        // 設定下拉選單 UI 的指定選項是已選擇的狀態
        setSelectedTable(
          firstSelectableChecklist.key as string
        )

        if (match.params.company_name) {

          history.replace(`/${PREFIX_COMPANY_URL}/${match.params.company_name}/${PREFIX_TEAM_URL}/${match.params.team_key}/checklist/${firstSelectableChecklist.key}`)

        } else if ( !match.params.company_name) {
          history.replace(`/${PREFIX_TEAM_URL}/${match.params.team_key}/checklist/${firstSelectableChecklist.key}`)
          
        }

        /**
         * 此區域是處理下拉選單的選項是已選擇狀態，
         * 以及
         * 是否在 url 沒有 match.params.key 值的情境下，取代當前網址，令其增加 checklist/:key 
         * 
         * Notice: 不使用 onChangeSelectedChecklist() 是因為此處需要使用 history.replace()
         * end
         */

      } else {

        /**
         * 如果沒有表格時，則直接取消查詢並且清除 查詢表格資料的 redux store
         */

        dispatch(fetchChecklistPub.cancel())
        dispatch(
          dispatch(resetChecklistPub())
        )
      }

    } else if (match.params.key) {
      /**
       * Notice: 
       * 1. 處理 {match.params.key (表格instance的key值) 屬於 js truthy 真值範圍} 且同時
       * {listOfChecklist 有值或無值} 的條件。
       * 2. 此處也處理使用者已選取標籤，且網頁有表格 instance 的key 值（match.params.key）
       * 
       * Action: 優先使用 match.params.key 作為查詢後端 API 的參數
       */

      dispatch(
        fetchChecklistPub.request({
          key: match.params.key,
        })
      )

      const foundTable = listOfChecklist.find((elem: IChecklist) => elem.key && elem.key === match.params.key)

      if (foundTable) {
        /**
         * 若當前已選擇標籤的檢核表清單找到 符合的表格，則令在下拉選單的該表格是已勾選狀態
         */

        // 設定下拉選單 UI 的指定選項是已選擇的狀態
        setSelectedTable(foundTable.key as string)
        return;
      }

      /**
       * 若下拉選單中的表格沒有符合 match.params.key 的表格，
       * 則預設選第一筆可選擇的表格
       */
      const firstSelectableChecklist = listOfChecklist.find(elem => {
        return elem.isSelectable === true
      })

      if (firstSelectableChecklist) {
        onChangeSelectedChecklist({detail: {value: firstSelectableChecklist.key}} as CustomEvent)

        return;
      }

      dispatch(fetchChecklistPub.cancel())
      dispatch(resetChecklistPub())

    } else {
      /**
       * Notice: 
       * 處理 {match.params.key (表格instance的key值) 屬於 js falsy 假值範圍} 且同時
       * {listOfChecklistPub.checklists 是無值} 的條件。
       * 
       * Action: 發出 fetchChecklistPub.cancel() ，使 redux-observable store 的 checklistPub
       * 回歸於 initialState (狀態樹只有一個屬性名稱 error，而且是 null，
       * 視為等同於 initialState)
       */

      dispatch(
        fetchChecklistPub.cancel(),
      )
      dispatch(resetChecklistPub())
    }

    setIsPageLoading(false)

  }, [match.params.key, listOfChecklist])


  useEffect(() => {
    if (checklistPub.name) {
      document.title = `${checklistPub.name} - ${t('Suiqui Resume')}`
    }
  }, [checklistPub.name])

  useEffect(() => {
    if (checklistPub.error) {
      setIsPageLoading(false)
    }
  }, [checklistPub.error, setIsPageLoading])

  // 增加與刪除使用者已選擇的標籤
  const toggleSelectedTag = useCallback((tagName: string) => {

    const foundIndex = listOfSelectedTag.findIndex(tag => tag == tagName)

    if (foundIndex >= 0) {
      setListOfSelectedTag([])
    } else {
      setListOfSelectedTag([tagName])
    }

  }, [listOfSelectedTag]);

  const doRefresh = (event: CustomEvent<RefresherEventDetail>) => {
    dispatch(fetchChecklistPub.cancel())

    if (!match.params.key) {
      /**
       * 當網址沒有 checklist 的 key 值
       */

      if (listOfChecklistPub.team_key.length > 0) {
        /**
         * 若 redux store 的 listOfChecklistPub team_key 有值，直接查詢 list of checklist public 的最新值，
         * 這會自動一起查 listOfChecklistPub.checklists 清單第一筆檢核表 的資料(自動執行前面 useEffect 內的 fetchChecklistPub.request)
         * ex:  
         * fetchChecklistPub.request({
         *    key: listOfChecklistPub.checklists[0].key
         * })
         * 
         */
        setIsPageLoading(true)
        dispatch(
          fetchListOfChecklistPub.request({
            team_key: match.params.team_key,
            event, // Notice: 傳這個 event ，是為了讓查詢資料完畢後，關閉 IonRefresh 的轉圈圈圖示
          })
        )
        
      } else {
        /**
         * 如果 listOfChecklistPub.team_key 沒有值，則顯示錯誤訊息
         */
        dispatch(
          fetchListOfChecklistPub.failure(
            new Error('has any one within list of checklists')
          )
        )
      }

    } else {
      /**
       * 當網址有 checklist 的 key 值，做以下事情:
       * 1. 抓取 list of checklist public 的清單內容
       * 2. 直接使用網址的 checklist key 值，抓取檢核表instance的最新內容
       */
      dispatch(
        fetchListOfChecklistPub.request({
          team_key: match.params.team_key,
          event, // Notice: 傳這個 event ，是為了讓查詢資料完畢後，關閉 IonRefresh 的轉圈圈圖示
        })
      )
      dispatch(
        fetchChecklistPub.request({
          key: match.params.key,
          event, // Notice: 傳這個 event ，是為了讓查詢資料完畢後，關閉 IonRefresh 的轉圈圈圖示
        })
      )
    }
    
  }

  const scrollToTop = () => {
    // @ts-ignore
    contentRef.current!.scrollToTop()
  }

  const getStatusColor = useCallback((status: string) => {
    switch (status) {
      case 'Check':
        return 'success'
      case 'Recheck':
        return 'primary'
      case 'Fail':
        return 'danger'
      case 'Skip':
        return 'warning'
      default:
        return 'light'
    }
  }, [])

  const statusFormatter = (current: number, total: number) => {
    return `${current} / ${total}`
  }

  const openPhotoViewerModal = (
    event: React.MouseEvent<any, MouseEvent>,
    index: number,
    items: ISuiquiFile[]
  ) => {
    setCurrentItem(items[index])
    setCurrentItems(items)
    setCurrentIndex(index)

    if (items[index]?.is_panorama) {
      setShowPanoramaViewerModal(true)
    } else {
      setShowPhotoViewerModal(true)
    }
  }

  const dismissPhotoViewerModal = (values: any) => {
    setCurrentItem(null)
    setCurrentItems(null)
    setCurrentIndex(0)
    setShowPhotoViewerModal(false)
  }

  const dismissPanoramaViewerModal = (values: any) => {
    setCurrentItem(null)
    setCurrentItems(null)
    setCurrentIndex(0)
    setShowPanoramaViewerModal(false)
  }

  const handleSwitchQrCode = () => {
    scrollToTop()
    setShowQRCode(!showQRCode)
  }

  const getUrl = () => {
    return window.location.href

  }

  const handleZoomIn = () => {
    setZoomLevel(zoomLevel + 1 <= 8 ? zoomLevel + 1 : zoomLevel)
  }

  const handleZoomOut = () => {
    setZoomLevel(zoomLevel - 1 >= -3 ? zoomLevel - 1 : zoomLevel)
  }

  /**
   * 返回到公司的團隊列表頁
   */
  const goBackToCompanyPage = useCallback(() => {
    setIsGobackToCompanyPage(true)
    history.push(`/${PREFIX_COMPANY_URL}/${listOfChecklistPub.team?.company}`)

  }, [isGobackToCompanyPage, listOfChecklistPub.team?.company])

  

  useIonViewWillLeave(() => {
    // 離開此頁面時，清除相關資料

    // 強制設定 QR code 畫面是關閉, 不論使用者是否在離開頁面前, 正在顯示 QR code 畫面。
    setShowQRCode(false)

    // 強制設定已選擇標籤是空的
    setListOfSelectedTag([])

    // 強制設定下拉選單的檢核表陣列清單是空的
    setListOfChecklist([])

    // 強制 redux store 的檢核表陣列清單是無資料(undefined)
    dispatch(resetListOfChecklistPub())

    // 令 redux store 查詢檢核表資料，是無資料(undefined)
    dispatch(resetChecklistPub())

    // 令 isGobackToCompanyPage 回復成初始值
    setIsGobackToCompanyPage(false)

  })

  return (
    <IonPage className="page-checklist">
      <IonHeader className="no-border">
        <IonToolbar color="primary">
          <IonTitle>
            <IonButton class="logo immutable">
              {t('Suiqui Checklist')}
            </IonButton>
            <span>{t('Resume')}</span>
          </IonTitle>


          { listOfChecklistPub.team?.company &&
            <IonButtons slot="start">
              <IonButton color="light" fill="clear" onClick={() => goBackToCompanyPage()}>
                {/* 如果有公司名稱，則顯示上一頁按鈕，讓使用者回到公司的團隊列表頁面 */}
                <IonIcon slot="icon-only" icon={arrowBackOutline}/>
              </IonButton>
            </IonButtons>
          }

          <IonButtons slot="end">
            <IonButton 
              color="light"
              fill="clear" 
              onClick={handleZoomIn} >
              {/* 放大檢視 */}
              <IonIcon slot="icon-only" icon={addCircleOutline} />
            </IonButton>
            <IonButton
              color="light"
              fill="clear"
              onClick={handleZoomOut}>
              {/* 縮小檢視 */}
              <IonIcon slot="icon-only" icon={removeCircleOutline}></IonIcon>
            </IonButton>
            <IonButton
              color="light"
              fill="clear"
              onClick={() => handleSwitchQrCode()}>
              {/* 切換 QR code 畫面 */}
              <IonIcon slot="icon-only" icon={qrCode} />
            </IonButton>
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonContent
        ref={contentRef}
        scrollEvents={true}
        style={{
          zoom: 1 * (zoomLevel * 0.1 + 1),
        }}
      >
        <IonRefresher slot="fixed" onIonRefresh={doRefresh}>
          <IonRefresherContent></IonRefresherContent>
        </IonRefresher>

        <div
          hidden={!showQRCode}
          style={{
            width: '100%',
            height: '100%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
          className="qrcode-view"
        >
          {/* view of QR code */}

          <IonGrid
            className="centered"
            style={{
              paddingTop: '50px',
              width: '100%',
            }}
          >
            <IonRow className="ion-justify-content-center ion-margin-bottom " >
              {/* 團隊名稱 */}
              <h1>
                {/* 如果團隊有設定公開名稱，則顯示公開名稱; 團隊未設定公開名稱，顯示提示文字: (團隊尚未設定公開名稱) */}
                {listOfChecklistPub.team?.display_name ?? t('Team does not set a public name')}
              </h1>
            </IonRow>

            <IonRow className="ion-justify-content-center">
              <QRCode
                value={window.location.href}
                size={
                  isPlatform('mobile')
                    ? 256
                    : width > height
                    ? height * 0.5
                    : width * 0.5
                }
              />
            </IonRow>
            { 
            /* 如果有表格名稱，則顯示表格名稱; 若沒有表格名稱，則不顯示 */
              checklistPub?.name && (
                <IonRow className="ion-justify-content-center">
                  <IonItem lines="none" className="ion-text-wrap ion-text-center table-name">
                    {/* 表格名稱 */}
                    <h2>{checklistPub?.name}</h2>
                  </IonItem>
                </IonRow>
              )
            }

            <IonRow className="ion-justify-content-center">
              {/* 開新網頁 */}
              <IonButton href={getUrl()} target="_blank" color="'secondary'" fill='outline'>
                {t('Open new tab')}
              </IonButton>
              
              <CopyToClipboard text={getUrl()}>
                <IonButton
                  onClick={e => {
                    e.preventDefault()
                    e.stopPropagation()
                    setShowCopyUrlToast(true)
                  }}>
                  {/* 複製網址按鈕 */}
                  <IonIcon slot="start" icon={share} />
                  <IonLabel>{t('Copy url')}</IonLabel>
                </IonButton>
              </CopyToClipboard>
            </IonRow>
          </IonGrid>
        </div>

        <div className="section-team">
          {
            listOfChecklistPub.team?.display_name && (
              <IonText className="team-public-name ion-text-center">
                {/* 顯示團隊的公開名稱. 如果團隊有設定公開名稱，則顯示公開名稱 */}
                <h1>{listOfChecklistPub.team.display_name}</h1>
              </IonText>
            )
          }
          {
            !listOfChecklistPub.team?.display_name && (
              <IonText className="team-public-name ion-text-center">
                {/* 團隊未設定公開名稱，顯示提示文字: (團隊尚未設定公開名稱) */}
                <h1>{`(${t('Team does not set a public name')})`}</h1>
              </IonText>
            )
          }
          <div className="team-tag-list suiqui-normal-rwd-styling">
            {/* 團隊的標籤列表區 */}
            {/* Notice: 若未建立標籤則不顯示此區標籤 */}
            {
              tagList && tagList.map((elem: string, idx: number) => {
                return (
                  <IonButton fill={ listOfSelectedTag.includes(elem) ? "solid" : "outline"} 
                  shape='round' 
                  onClick={() => toggleSelectedTag(elem)} 
                  key={idx}
                  className="tag">
                    <IonLabel>{elem}</IonLabel>
                  </IonButton>
                )
              })
            }
          </div>
        </div>

        

        {isPageLoading === false && (<div className="list-of-checklist suiqui-normal-rwd-styling" >
          
          <IonSelect interface="popover" interfaceOptions={popOverConfigOptions}
          value={selectedTable} onIonChange={onChangeSelectedChecklist}
          placeholder={`${t('Please select a resume')}`}>
            {/* 
              下拉選單UI，內含已公開的檢核表 instance 列表清單
             */}
            {
              listOfChecklist.map((elem: IChecklist, idx: number) => {
                  return (
                    <IonSelectOption value={elem.key} key={idx}
                    disabled={!elem.isSelectable}>{elem.name}</IonSelectOption>
                  )
                })
            }
          </IonSelect>
          
          {checklistPub?.serial && (
            <IonLabel slot="end" className="ion-text-wrap ion-padding-end">
              {/* 20220818 Notice: 不知道這個的用途是什麼，目前先保留, 以後問趙哥是否要保留,
              TODO: 以後決定要不要刪掉,
              20220830 Notice: 趙哥說先留著 */}
              {t('Serial=')}
              {checklistPub.serial}
            </IonLabel>
          )}
          
        </div>
        )}

        {isPageLoading ? (
          <div className="ion-text-center centered">
            <IonSpinner color="primary" name="crescent"></IonSpinner>
          </div>
        ) : (
          <IonList>
            {listOfChecklistPub?.error === null && listOfChecklistPub?.checklists?.length === 0 && (
              <IonText color="danger" className="ion-text-center">
                {/* 顯示取得團隊的公開檢核表instance 列表數量是 0 時的錯誤訊息 */}
                <h2>{t('Please set at least one checklist to be public.')}</h2>
              </IonText>
            )}
            {listOfChecklistPub?.error && (
              <IonText color="danger" className="ion-text-center">
                {/* 顯示取得團隊的公開檢核表instance 列表資訊時的錯誤訊息 */}
                <h2>{t('Please enter correct team key.')}</h2>
              </IonText>
            )}
            {checklistPub?.error && (
              <IonText color="danger" className="ion-text-center">
                {/* 顯示取得公開的單一檢核表instance 資訊時的錯誤訊息  */}
                <h2>{t('Please enter correct checklist key.')}</h2>
              </IonText>
            )}

            <IonList className="section-custom-fields">
              {
                (checklistPub?.due_date || checklistPub?.custom_fields?.length > 0) && (
                  <IonListHeader className='header-of-custom-fields'>
                    {/* 履歷資訊 的文字 */}
                    {t('Resume information')}
                  </IonListHeader>
                )
              }
              {checklistPub?.due_date && (
                <IonRow key={-1}>
                  <IonCol>
                    <IonItem>
                      <IonLabel color="medium" className="ion-text-wrap">
                        {t('Due Date')}
                      </IonLabel>
                      <IonLabel className="ion-text-right ion-text-wrap">
                        {checklistPub?.due_date}
                        {checklistPub?.due_date_ext && (
                          <> ～ {checklistPub?.due_date_ext}</>
                        )}
                      </IonLabel>
                    </IonItem>
                  </IonCol>
                </IonRow>
              )}
              {checklistPub?.custom_fields?.map((field: any, index: number) => {
                // 表頭欄位區(客製化欄位)
                if (Array.isArray(field)) {
                  return (
                    <IonRow key={index}>
                      {field?.map((item: any, idx: number) => (
                        <IonCol key={idx}>
                          <IonItem>
                            <IonLabel color="medium" className="ion-text-wrap">
                              {item.key}
                            </IonLabel>
                            {/* 高亮顯示已被代入環境變數的表頭欄位 */}
                            <IonLabel
                              className={classNames(
                                'ion-text-right',
                                'ion-text-wrap',
                                { 'evaluated': item.eval_value !== item.value }
                              )}
                            >
                              {item.eval_value}
                            </IonLabel>
                          </IonItem>
                        </IonCol>
                      ))}
                    </IonRow>
                  )
                } else {
                  return (
                    <IonRow key={index}>
                      <IonCol>
                        <IonItem>
                          <IonLabel color="medium" className="ion-text-wrap">
                            {field.key}
                          </IonLabel>
                          {/* 高亮顯示已被代入環境變數的表頭欄位 */}
                          <IonLabel
                            className={classNames(
                              'ion-text-right',
                              'ion-text-wrap',
                              { 'evaluated': field.eval_value !== field.value }
                            )}
                          >
                            {field.eval_value}
                          </IonLabel>
                        </IonItem>
                      </IonCol>
                    </IonRow>
                  )
                }
              })}
            </IonList>

            {checklistPub?.children?.map((child: IChecklist, index: number) => {
              if (child?.children?.length) {
                return (
                  <div key={index}>
                    <IonCardHeader color="light">
                      <IonLabel>{child.name}</IonLabel>
                    </IonCardHeader>
                    {child?.children?.map((item: IChecklist, index: number) => {
                      return (
                        <IonCard
                          key={index}
                          className={getStatusColor(item.status)}
                        >
                          <IonCardHeader>
                            <IonCardTitle>
                              <IonChip color={getStatusColor(item.status)}>
                                {t(`Status ${item.status}`)}
                              </IonChip>
                              {item.name}
                            </IonCardTitle>
                          </IonCardHeader>
                          {item.standard && (
                            <IonItem lines="none">
                              <IonLabel
                                color="medium"
                                className="ion-text-wrap"
                              >
                                {/* 檢查標準的欄位名稱 */}
                                {t('Checklist Standard=')}
                              </IonLabel>
                              <IonLabel className="ion-text-wrap">
                                {item.standard}
                              </IonLabel>
                            </IonItem>
                          )}

                          {item.check_result && (
                            <IonItem lines="none">
                              <IonLabel
                                color="medium"
                                className={classNames({
                                  'ion-text-wrap': true,
                                  [`${getStatusColor(item.status)}`]: true,
                                })}
                              >
                                {/* 檢查結果的欄位名稱 */}
                                {t('Checklist Result=')}
                              </IonLabel>
                              <IonLabel
                                className={classNames({
                                  'ion-text-wrap': true,
                                  [`${getStatusColor(item.status)}`]: true,
                                })}
                              >
                                {item.check_result}
                              </IonLabel>
                            </IonItem>
                          )}
                          {item?.files && (
                            <div className="photo-slider">
                              <Carousel
                                centerMode
                                centerSlidePercentage={
                                  item.files.length === 1
                                    ? 100
                                    : isPlatform('mobile')
                                    ? 50
                                    : 25
                                }
                                emulateTouch
                                useKeyboardArrows={!showPhotoViewerModal}
                                showThumbs={false}
                                statusFormatter={statusFormatter}
                                showIndicators={false}
                              >
                                {item.files.map(
                                  (file: ISuiquiFile, index: number) => {
                                    return (
                                      <div
                                        className="openable"
                                        onClick={event =>
                                          openPhotoViewerModal(
                                            event,
                                            index,
                                            item.files
                                          )
                                        }
                                        key={index}
                                      >
                                        <img src={`${file.url}=h240`}></img>
                                        {file?.memo && (
                                          <p className="legend">{file.memo}</p>
                                        )}
                                      </div>
                                    )
                                  }
                                )}
                              </Carousel>
                            </div>
                          )}
                        </IonCard>
                      )
                    })}
                  </div>
                )
              } else {
                return (
                  <IonCard key={index} className={getStatusColor(child.status)}>
                    <IonCardHeader>
                      <IonCardTitle>
                        <IonChip color={getStatusColor(child.status)}>
                          {t(`Status ${child.status}`)}
                        </IonChip>
                        {child.name}
                      </IonCardTitle>
                    </IonCardHeader>
                    {child.standard && (
                      <IonItem lines="none">
                        <IonLabel color="medium" className="ion-text-wrap">
                          {/* 檢查標準的欄位名稱 */}
                          {t('Checklist Standard=')}
                        </IonLabel>
                        <IonLabel className="ion-text-wrap">
                          {child.standard}
                        </IonLabel>
                      </IonItem>
                    )}

                    {child.check_result && (
                      <IonItem lines="none">
                        <IonLabel
                          color="medium"
                          className={classNames({
                            'ion-text-wrap': true,
                            [`${getStatusColor(child.status)}`]: true,
                          })}
                        >
                          {/* 檢查結果的欄位名稱 */}
                          {t('Checklist Result=')}
                        </IonLabel>
                        <IonLabel
                          className={classNames({
                            'ion-text-wrap': true,
                            [`${getStatusColor(child.status)}`]: true,
                          })}
                        >
                          {child.check_result}
                        </IonLabel>
                      </IonItem>
                    )}
                    {child?.files && (
                      <div className="photo-slider">
                        <Carousel
                          centerMode
                          centerSlidePercentage={
                            child.files.length === 1
                              ? 100
                              : isPlatform('mobile')
                              ? 50
                              : 25
                          }
                          emulateTouch
                          useKeyboardArrows={!showPhotoViewerModal}
                          showThumbs={false}
                          statusFormatter={statusFormatter}
                          showIndicators={false}
                        >
                          {child.files.map(
                            (file: ISuiquiFile, index: number) => {
                              return (
                                <div
                                  className="openable"
                                  onClick={event =>
                                    openPhotoViewerModal(
                                      event,
                                      index,
                                      child.files
                                    )
                                  }
                                  key={index}
                                >
                                  <img src={`${file.url}=h240`}></img>
                                  {file?.memo && (
                                    <p className="legend">{file.memo}</p>
                                  )}
                                </div>
                              )
                            }
                          )}
                        </Carousel>
                      </div>
                    )}
                  </IonCard>
                )
              }
            })}
            {checklistPub?.key && !checklistPub?.error && (
              <div className="ion-text-center">
                <IonButton
                  color="warning"
                  fill="clear"
                  size="large"
                  slot="icon-only"
                  onClick={scrollToTop}
                >
                  <IonIcon icon={caretUp} />
                </IonButton>
              </div>
            )}
          </IonList>
        )}

        <IonFooter className="ion-text-center">
          <IonButton
            fill="clear"
            size="small"
            strong={true}
            className="immutable"
          >
            <IonLabel color="medium">
              {t('Suiqui Resume')} {`${process.env.REACT_APP_VERSION}`}
            </IonLabel>
          </IonButton>
        </IonFooter>
      </IonContent>
      <IonLoading
        isOpen={showLoading}
        onDidDismiss={() => setShowLoading(false)}
        spinner="crescent"
      />

      <IonModal
        isOpen={showPhotoViewerModal}
        showBackdrop={false}
        backdropDismiss={false}
        cssClass="modal-fullscreen"
      >
        {showPhotoViewerModal && (
          <PhotoViewerModal
            isOpen={showPhotoViewerModal}
            item={currentItem}
            items={currentItems}
            index={currentIndex}
            dismiss={dismissPhotoViewerModal}
          />
        )}
      </IonModal>
      <IonModal
        isOpen={showPanoramaViewerModal}
        showBackdrop={false}
        backdropDismiss={false}
        cssClass="modal-fullscreen"
      >
        {showPanoramaViewerModal && (
          <PanoramaViewerModal
            isOpen={showPanoramaViewerModal}
            item={currentItem}
            items={currentItems}
            index={currentIndex}
            dismiss={dismissPanoramaViewerModal}
          />
        )}
      </IonModal>

      <IonToast
        isOpen={showCopyUrlToast}
        onDidDismiss={() => setShowCopyUrlToast(false)}
        message={t('The link is copied to clipboard.')}
        duration={3000}
      />
    </IonPage>
  )
}

export default V2Checklist
