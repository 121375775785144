import {
  IonButton,
  IonContent,
  IonFooter,
  IonHeader,
  IonLabel,
  IonPage,
  IonTitle,
  IonToolbar,
} from '@ionic/react'
import React from 'react'
import { useTranslation } from 'react-i18next'
import ExploreContainer from '../components/ExploreContainer'
import './Home.scss'

const Home: React.FC = () => {
  const { t } = useTranslation()

  return (
    <IonPage className="page-home">
      <IonHeader>
        <IonToolbar>
          <IonTitle>{t('Suiqui Resume')}</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <IonHeader collapse="condense">
          <IonToolbar>
            <IonTitle size="large">{t('Suiqui Resume')}</IonTitle>
          </IonToolbar>
        </IonHeader>
        <ExploreContainer />
      </IonContent>
      <IonFooter className="ion-text-center">
        <IonButton
          fill="clear"
          size="small"
          strong={true}
          className="immutable"
        >
          <IonLabel color="medium">
            {t('Suiqui Resume')} {`${process.env.REACT_APP_VERSION}`}
          </IonLabel>
        </IonButton>
      </IonFooter>
    </IonPage>
  )
}

export default Home
