import { ajax } from 'rxjs/ajax'
import { API } from '../helpers/base'
import { IChecklist } from '../models/checklist'

export const get = ({ params }: { params: Partial<IChecklist> }) =>
  ajax({
    url: API('checklist', 'CHECKLIST_PUB'),
    method: 'POST',
    responseType: 'json',
    headers: {
      'Content-Type': 'application/json',
    },
    body: {
      ...params,
    },
  })
