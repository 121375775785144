import { combineEpics, Epic } from 'redux-observable'
import { of } from 'rxjs'
import {
  catchError,
  filter,
  finalize,
  map,
  switchMap,
  takeUntil,
} from 'rxjs/operators'
import {
  ActionType,
  createAsyncAction,
  createCustomAction,
  createReducer,
  getType,
  isActionOf,
} from 'typesafe-actions'
import { IChecklist } from '../../models/checklist'
import { IRefresher } from '../../models/page'
import * as Providers from '../../providers'
import { RootState } from '../ducks'
import { RootAction } from './types'

export const fetchChecklistPub = createAsyncAction(
  'FETCH_CHECKLIST_PUB_REQUEST',
  'FETCH_CHECKLIST_PUB_SUCCESS',
  'FETCH_CHECKLIST_PUB_FAILURE',
  'FETCH_CHECKLIST_PUB_CANCEL'
)<
  Pick<IChecklist & IRefresher, 'key' | 'event'>,
  IChecklist,
  Error,
  undefined
>()

// 用途：重置此處的redux store 狀態樹是 initialState
export const resetChecklistPub = createCustomAction('FETCH_CHECKLIST_PUB_RESET')

const initialState = {
  error: undefined,
}

export const fetchChecklistPubEpic: Epic<
  RootAction,
  RootAction,
  RootState,
  typeof Providers
> = (action$, state$, { checklistPubAPI }) =>
  action$.pipe(
    filter(isActionOf(fetchChecklistPub.request)),
    switchMap(action =>
      checklistPubAPI
        .get({ params: action.payload })
        .pipe(
          finalize(() => {
            const { event } = action.payload
            if (event && event.target) {
              /**
               * Notice: 這是為了處理畫面上的 IonRefresher  pulldown-to-refresh 事件，
               * 要讓旋轉的 icon 消失
               */
              // @ts-ignore
              event.target.complete()
            }
          })
        )
        .pipe(
          map(data => fetchChecklistPub.success(data.response)),
          catchError((message: Error) =>
            of(fetchChecklistPub.failure(message))
          ),
          takeUntil(action$.pipe(filter(isActionOf(fetchChecklistPub.cancel))))
        )
    )
  )

export type ChecklistPubAction = ActionType<typeof fetchChecklistPub>

export const checklistPubReducer = createReducer(initialState)
  .handleAction(
    getType(fetchChecklistPub.request),
    (state: any, { payload }: any) => ({
      ...initialState,
    })
  )
  .handleAction(
    getType(fetchChecklistPub.success),
    (state: any, { payload }: any) => ({
      ...payload,
      error: null,
    })
  )
  .handleAction(
    getType(fetchChecklistPub.failure),
    (state: any, { payload }: any) => ({
      error: payload,
    })
  )
  .handleAction(
    getType(fetchChecklistPub.cancel),
    (state: any, { payload }: any) => ({
      error: null,
    })
  )
  .handleAction(
    getType(resetChecklistPub), // 用途：重置此處的redux store 狀態樹是 initialState
    (state: any, { payload }: any) => ({
      ...initialState,
    })
  )
const checklistPubEpic = combineEpics<any>(fetchChecklistPubEpic)
export default checklistPubEpic
