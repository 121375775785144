import { isPlatform } from '@ionic/core'
import * as mimeTypes from 'mime-types'
import { url } from '../models/base'
import { IFile, ISuiquiFile, ISuiquiVersionFile } from '../models/suiquiFile'
import { IUser } from '../models/user'

export const isVersionMedia = (file: IFile) => {
  if (file.blob_info?.content_type.startsWith('video/mp4')) {
    return true
  } else if (file.blob_info?.content_type.startsWith('audio/')) {
    return true
  } else if (
    file.blob_info?.content_type.startsWith('application/pdf') &&
    !isPlatform('ios')
  ) {
    return true
  }
  return false
}

export const getPreviewImage = (file: File) => {
  // BUGGY AND LAG
  return file.type.startsWith('image/') ? window.URL.createObjectURL(file) : ''
}

export const isRetina = () => {
  const mediaQuery =
    '(-webkit-min-device-pixel-ratio: 1.5), (min--moz-device-pixel-ratio: 1.5), (-o-min-device-pixel-ratio: 3/2), (min-resolution: 1.5dppx)'
  if (window.devicePixelRatio > 1) return true
  if (window.matchMedia && window.matchMedia(mediaQuery).matches) return true
  return false
}

export const getImageUrl = (
  file: IFile,
  size?: number,
  sizeAndCrop?: string
) => {
  return file?.is_image
    ? size !== undefined
      ? `${file.url}=s${isRetina ? size * 2 : size}${
          sizeAndCrop ? `-${sizeAndCrop}` : ''
        }`
      : file.url
    : ''
}

export const getAvatarUrl = (
  currentUser: IUser,
  size = 36,
  sizeAndCrop = 'c'
) => {
  return currentUser?.profile_pic
    ? `${currentUser?.profile_pic}=s${isRetina ? size * 2 : size}${
        sizeAndCrop ? `-${sizeAndCrop}` : ''
      }`
    : require('../assets/img/default.png')
}

export const getFallbackImage = (event: any) => {
  event.target.onerror = null
  event.target.src = require('../assets/img/suiqui-placeholder.png')
  event.target.srcSet = `${require('../assets/img/suiqui-placeholder@2x.png')} 2x`
}

export const getDownloadUrl = (file: IFile) => {
  return file.is_image ? `${file.url}=s0` : file.url
}

export const openDocument = (file: IFile) => {
  file.url && window.open(file.url, '_blank')
}

export const dataURLtoBlob = (dataUrl: url) => {
  if (!dataUrl) return ''
  const arr = dataUrl.split(',')
  const mime = arr[0].match(/:(.*?);/)![1]
  const bstr = atob(arr[1])
  let n = bstr.length
  const u8arr = new Uint8Array(n)
  while (n--) {
    u8arr[n] = bstr.charCodeAt(n)
  }
  return new Blob([u8arr], { type: mime })
}

export const hashCode = (s: string) =>
  s
    .split('')
    .reduce((a: any, b: any) => ((a << 5) - a + b.charCodeAt(0)) | 0, 0)

export const getFilenameNoExt = (filename: string) => {
  if (!filename) return
  const name = filename.substr(0, filename.lastIndexOf('.')) || filename
  return name
}

export const getFilenameSplit = (filename: string) => {
  if (!filename) return { name: filename, ext: null }
  const _filenameArray = filename.split('.')
  const name = filename.substr(0, filename.lastIndexOf('.')) || filename
  const ext =
    _filenameArray.length > 1 ? _filenameArray.pop()?.toLowerCase() : null
  return {
    name,
    ext,
  }
}

export const getFileExt = (file: IFile) => {
  if (file?.content_type) {
    const ext = mimeTypes.extension(file.content_type)
    if (ext === 'mpga') {
      return getFilenameSplit(`${file.name}`).ext
    } else if (ext === 'bin') {
      const ext2 = mimeTypes.extension(
        mimeTypes.contentType(file.name || '') || ''
      )
      if (ext2 === 'markdown') {
        return 'md'
      }
      return ext2
    } else {
      return ext
    }
  }
  if (file?.type) return mimeTypes.extension(file.type)
  return 'file'
}

export const getProgressKey = (file: File, index: number) =>
  hashCode(`${file.name}-${index}`) || 0

export const getIFileProgressKey = (file: IFile) =>
  hashCode(`${file?.key}`) || 0

export const getProgressBarColor = (pFile: any) => {
  if (pFile?.isDone) return 'success'
  if (pFile?.isCancel && pFile?.isDone) return 'medium'
  if (pFile?.isCancel) return 'danger'
  return 'warning'
}

export const getProgressNumber = (pFile: any) => {
  return pFile?.isDone ? 100 : pFile?.percentage / 100 || 0
}

export const getUrl = (file: ISuiquiFile) => {
  const isLocalhost = Boolean(
    window.location.hostname === 'localhost' ||
      // [::1] is the IPv6 localhost address.
      window.location.hostname === '[::1]' ||
      // 127.0.0.1/8 is considered localhost for IPv4.
      window.location.hostname.match(
        /^127(?:\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3}$/
      )
  )

  return isLocalhost
    ? `${window.location.protocol}//${window.location.host}/file/${file.key}`
    : `${process.env.REACT_APP_SITE_ENDPOINT}/file/${file.key}`
}

export const getFilenameWithVersion = (item: ISuiquiFile, skipV1: boolean) => {
  if (!item?.version_file?.version) {
    return item.name
  }
  if (skipV1 && item.version_file.version === 1) {
    return item.name
  }
  const filename = getFilenameSplit(item.name)
  return `${filename.name}_v${item?.version_file?.version}.${filename.ext}`
}

export const getVersionFilename = (file: ISuiquiVersionFile, name?: string) => {
  if (!file.version) {
    return name || file.name
  }
  const filename = getFilenameSplit(name || file.name)
  return `${filename.name}_v${file.version}.${filename.ext}`
}

export const getVersionUrl = (file: ISuiquiVersionFile) => {
  const isLocalhost = Boolean(
    window.location.hostname === 'localhost' ||
      // [::1] is the IPv6 localhost address.
      window.location.hostname === '[::1]' ||
      // 127.0.0.1/8 is considered localhost for IPv4.
      window.location.hostname.match(
        /^127(?:\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3}$/
      )
  )

  return isLocalhost
    ? `${window.location.protocol}//${window.location.host}/file/version/${file.key}`
    : `${process.env.REACT_APP_SITE_ENDPOINT}/file/version/${file.key}`
}
