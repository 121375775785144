interface ServiceWorkerNotificationOptions {
  tag?: string
}

interface ServiceWorkerRegistration {
  active?: ServiceWorker
  installing?: ServiceWorker
  onupdatefound?: (event?: Event) => any
  pushManager: PushManager
  scope: string
  waiting?: ServiceWorker
  getNotifications(
    options?: ServiceWorkerNotificationOptions
  ): Promise<Array<Notification>>
  update(): void
  unregister(): Promise<boolean>
}

interface ServiceWorkerEvent extends Event {
  target: (Partial<ServiceWorker> & EventTarget) | null
}

export default {
  onUpdate: async (registration: ServiceWorkerRegistration) => {
    if ('BroadcastChannel' in self) {
      const bc = new BroadcastChannel('app-update')
      bc.postMessage('App has updated.')
    } else {
      window.postMessage(
        {
          channelName: 'app-update',
          payload: 'App has updated.',
        },
        window.location.origin
      )
    }
  },
  onSuccess: (registration: any) => {
    console.info('service worker on success state')
    console.log(registration)
    //@ts-ignore
    window.isUpdateAvailable = false
  },
}
