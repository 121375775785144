import { combineEpics } from 'redux-observable'
import { of, pipe } from 'rxjs'
import { AjaxResponse } from 'rxjs/ajax'
import { catchError, concatMap, tap } from 'rxjs/operators'
import { PayloadAction } from 'typesafe-actions'
import {
  forceAuthErrorAlert,
  forceAuthLogout,
  prepareAuthTeamSwitching,
} from './auth'
import checklistPubEpic from './checklist-pub'
import listOfChecklistPubEpic from './list-of-checklist-pub'
import listOfTeamsOfCompanyPubEpic from './list-of-teams-of-a-company-pub'
import { ReactRouterAction } from './types'

export default (action$: any, state$: any, deps: any) => {
  return combineEpics<any>(
    checklistPubEpic,
    listOfChecklistPubEpic,
    listOfTeamsOfCompanyPubEpic
    )(action$, state$, deps).pipe(
    tap(
      (action: PayloadAction<string, AjaxResponse | ReactRouterAction>) => {}
    ),
    catchError((error, source$) => {
      return source$
    }),
    pipe(
      concatMap((action: PayloadAction<string, AjaxResponse>) => {
        if (action.payload.status === 401) {
          return of(action, forceAuthLogout(action))
        } else if (action.payload.status === 400) {
          return of(action)
        } else if (action.payload.status === 403) {
          return of(action)
        } else if (action.payload.status === 203) {
          return of(
            {
              ...action,
              payload: undefined,
            },
            prepareAuthTeamSwitching(action.payload)
          )
        } else if (
          action.payload.status === 500 ||
          (action.payload.status >= 402 && action.payload.status < 500)
        ) {
          return of(action, forceAuthErrorAlert(action.payload))
        } else {
          return of(action)
        }
      })
    )
  )
}
