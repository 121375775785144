import { defineCustomElements } from '@ionic/pwa-elements/loader'
import React from 'react'
import ReactDOM from 'react-dom'
import { Provider } from 'react-redux'
import App from './App'
import * as serviceWorker from './serviceWorker'
import store from './store'
import swConfig from './swConfig'

ReactDOM.render(
  <Provider store={store}>
    <App />
  </Provider>,
  document.getElementById('root')
)

defineCustomElements(window)

if ('serviceWorker' in navigator) {
  serviceWorker.register(swConfig as any)
}
