import { combineEpics, Epic } from 'redux-observable'
import { of } from 'rxjs'
import {
  catchError,
  filter,
  finalize,
  map,
  switchMap,
  takeUntil,
} from 'rxjs/operators'
import {
  ActionType,
  createCustomAction,
  createAsyncAction,
  createReducer,
  getType,
  isActionOf,
} from 'typesafe-actions'
import { IListOfChecklist } from '../../models/list-of-checklist'
import { IRefresher } from '../../models/page'
import * as Providers from '../../providers'
import { RootState } from '../ducks'
import { RootAction } from './types'

/**
 * 這個檔案用途是取得清單，關於設定是公開的檢核表instance
 * Notice: 大部分內容自 src/store/epics/checklist-pub.ts 複製過來.
 */

export const fetchListOfChecklistPub = createAsyncAction(
  'FETCH_LIST_OF_CHECKLIST_PUB_REQUEST',
  'FETCH_LIST_OF_CHECKLIST_PUB_SUCCESS',
  'FETCH_LIST_OF_CHECKLIST_PUB_FAILURE',
  'FETCH_LIST_OF_CHECKLIST_PUB_CANCEL'
)<
  Pick<IListOfChecklist & IRefresher, 'team_key' | 'event'>,
  IListOfChecklist,
  Error,
  undefined
>()

// 用途：重置此處的redux store 狀態樹是 initialState
export const resetListOfChecklistPub = createCustomAction('FETCH_LIST_OF_CHECKLIST_PUB_RESET')

const initialState = {
  error: undefined,
  team_key: '',
  checklists: [],
}

export const fetchListOfChecklistPubEpic: Epic<
  RootAction,
  RootAction,
  RootState,
  typeof Providers
> = (action$, state$, { listOfChecklistPubAPI }) =>
  action$.pipe(
    filter(isActionOf(fetchListOfChecklistPub.request)),
    switchMap(action =>
      listOfChecklistPubAPI
        .get({ params: action.payload })
        .pipe(
          finalize(() => {
            const { event } = action.payload
            if (event && event.target) {
              /**
               * Notice: 這是為了處理畫面上的 IonRefresher  pulldown-to-refresh 事件，
               * 要讓旋轉的 icon 消失
               */
              
              // @ts-ignore
              event.target.complete()
            }
          })
        )
        .pipe(
          map(data => fetchListOfChecklistPub.success(data.response)),
          catchError((message: Error) =>
            of(fetchListOfChecklistPub.failure(message))
          ),
          takeUntil(action$.pipe(filter(isActionOf(fetchListOfChecklistPub.cancel))))
        )
    )
  )

export type ListOfChecklistPubAction = ActionType<typeof fetchListOfChecklistPub>

export const listOfChecklistPubReducer = createReducer(initialState)
  .handleAction(
    getType(fetchListOfChecklistPub.request),
    (state: any, { payload }: any) => ({
      ...state,
      team_key: payload.team_key,
    })
  )
  .handleAction(
    getType(fetchListOfChecklistPub.success),
    (state: any, { payload }: any) => ({
      ...state,
      ...payload,
      error: null,
    })
  )
  .handleAction(
    getType(fetchListOfChecklistPub.failure),
    (state: any, { payload }: any) => ({
      ...state,
      error: payload,
    })
  )
  .handleAction(
    getType(fetchListOfChecklistPub.cancel),
    (state: any, { payload }: any) => ({
      error: null,
    })
  )
  .handleAction(
    getType(resetListOfChecklistPub), // 用途：重置此處的redux store 狀態樹是 initialState
    (state: any, { payload }: any) => ({
      ...initialState
    })
  )

/**
 * Notice: listOfChecklistPubEpic 會讓 src/store/epics/index.ts 導入，
 * 作為全系統範圍適用的 redux store 元件
 */
const listOfChecklistPubEpic = combineEpics<any>(fetchListOfChecklistPubEpic)
export default listOfChecklistPubEpic
