import { key } from '../models/base'

export const API = (url: string, name?: string) => {
  let endpoint
  switch (name) {
    case 'AUTH':
      endpoint = `${process.env.REACT_APP_AUTH_ENDPOINT}`
      break
    case 'AUTH_PUB':
      endpoint = `${process.env.REACT_APP_AUTH_PUB_API_ENDPOINT}`
      break
    case 'LIBRARY_PUB':
      endpoint = `${process.env.REACT_APP_LIBRARY_PUB_API_ENDPOINT}`
      break
    case 'CHECKLIST_PUB':
      endpoint = `${process.env.REACT_APP_CHECKLIST_PUB_API_ENDPOINT}`
      break
    case 'DAILYREPORT_PUB':
      endpoint = `${process.env.REACT_APP_DAILYREPORT_PUB_API_ENDPOINT}`
      break
    default:
      endpoint = `${process.env.REACT_APP_AUTH_ENDPOINT}`
      break
  }
  return `${endpoint}/${url}/`
}

export const JWT = (token: key) => {
  return {
    Authorization: `JWT ${token}`,
    'Content-Type': 'application/json',
  }
}

export const JWT_UPLOAD = (token: key) => {
  return {
    Authorization: `JWT ${token}`,
  }
}

export const JWT_DOWNLOAD = (token: key) => {
  return {
    Authorization: `JWT ${token}`,
  }
}

export const inFunctionList = (name: string, functionList: string[] = []) => {
  return functionList.indexOf(name) > -1
}
