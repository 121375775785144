import { RefresherEventDetail } from '@ionic/core'
import {
  IonButton,
  IonButtons,
  IonCard,
  IonCardHeader,
  IonCardTitle,
  IonChip,
  IonCol,
  IonContent,
  IonFooter,
  IonGrid,
  IonHeader,
  IonIcon,
  IonItem,
  IonItemDivider,
  IonLabel,
  IonList,
  IonLoading,
  IonModal,
  IonPage,
  IonRefresher,
  IonRefresherContent,
  IonRow,
  IonSpinner,
  IonText,
  IonTitle,
  IonToast,
  IonToolbar,
  isPlatform,
} from '@ionic/react'
import useComponentSize from '@rehooks/component-size'
import classNames from 'classnames'
import {
  addCircleOutline,
  caretUp,
  qrCode,
  removeCircleOutline,
  share,
} from 'ionicons/icons'
import QRCode from 'qrcode.react'
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import CopyToClipboard from 'react-copy-to-clipboard'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { Carousel } from 'react-responsive-carousel'
import 'react-responsive-carousel/lib/styles/carousel.min.css'
import PanoramaViewerModal from '../modals/PanoramaViewerModal'
import PhotoViewerModal from '../modals/PhotoViewerModal'
import { IChecklist } from '../models/checklist'
import { ISuiquiFile } from '../models/suiquiFile'
import { fetchChecklistPub } from '../store/epics/checklist-pub'
import { ReactRouterAction } from '../store/epics/types'
import './Checklist.scss'

const Checklist: React.FC<ReactRouterAction> = ({ match }) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const contentRef = useRef(null)
  const checklistPub = useSelector((state: any) => state.checklistPub)
  const [isPageLoading, setIsPageLoading] = useState(false)
  const [showLoading, setShowLoading] = useState(false)
  const [currentItem, setCurrentItem] = useState()
  const [currentItems, setCurrentItems] = useState()
  const [currentIndex, setCurrentIndex] = useState()
  const [showPhotoViewerModal, setShowPhotoViewerModal] = useState(false)
  const [showPanoramaViewerModal, setShowPanoramaViewerModal] = useState(false)
  const [showQRCode, setShowQRCode] = useState(false)
  const [showCopyUrlToast, setShowCopyUrlToast] = useState(false)
  const [zoomLevel, setZoomLevel] = useState(0)
  const { width, height } = useComponentSize(contentRef)

  useMemo(() => {
    if (!match.params.key) return
    setIsPageLoading(true)
    dispatch(
      fetchChecklistPub.request({
        key: match.params.key,
      })
    )
  }, [dispatch, match.params.key, setIsPageLoading])

  useEffect(() => {
    if (checklistPub.key) {
      setShowLoading(false)
      setIsPageLoading(false)
    }
  }, [setShowLoading, setIsPageLoading, checklistPub.key])

  useMemo(() => {
    if (checklistPub.print_name) {
      document.title = `${checklistPub.print_name} - ${t('Suiqui Resume')}`
    }
  }, [checklistPub.print_name])

  useMemo(() => {
    if (checklistPub.error) {
      setIsPageLoading(false)
    }
  }, [checklistPub.error, setIsPageLoading])

  const doRefresh = (event: CustomEvent<RefresherEventDetail>) => {
    dispatch(fetchChecklistPub.cancel())

    dispatch(
      fetchChecklistPub.request({
        key: match.params.key,
        event,
      })
    )
  }

  const scrollToTop = () => {
    // @ts-ignore
    contentRef.current!.scrollToTop()
  }

  const getStatusColor = useCallback((status: string) => {
    switch (status) {
      case 'Check':
        return 'success'
      case 'Recheck':
        return 'primary'
      case 'Fail':
        return 'danger'
      case 'Skip':
        return 'warning'
      default:
        return 'light'
    }
  }, [])

  const reloadData = (event: any) => {
    setIsPageLoading(true)
    dispatch(
      fetchChecklistPub.request({
        key: match.params.key,
        event: undefined,
      })
    )
  }

  const statusFormatter = (current: number, total: number) => {
    return `${current} / ${total}`
  }

  const openPhotoViewerModal = (
    event: React.MouseEvent<any, MouseEvent>,
    index: number,
    items: ISuiquiFile[]
  ) => {
    setCurrentItem(items[index])
    setCurrentItems(items)
    setCurrentIndex(index)

    if (items[index]?.is_panorama) {
      setShowPanoramaViewerModal(true)
    } else {
      setShowPhotoViewerModal(true)
    }
  }

  const dismissPhotoViewerModal = (values: any) => {
    setCurrentItem(null)
    setCurrentItems(null)
    setCurrentIndex(0)
    setShowPhotoViewerModal(false)
  }

  const dismissPanoramaViewerModal = (values: any) => {
    setCurrentItem(null)
    setCurrentItems(null)
    setCurrentIndex(0)
    setShowPanoramaViewerModal(false)
  }

  const handleSwitchQrCode = () => {
    scrollToTop()
    setShowQRCode(!showQRCode)
  }

  const getUrl = (item: any) => {
    const isLocalhost = Boolean(
      window.location.hostname === 'localhost' ||
        // [::1] is the IPv6 localhost address.
        window.location.hostname === '[::1]' ||
        // 127.0.0.1/8 is considered localhost for IPv4.
        window.location.hostname.match(
          /^127(?:\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3}$/
        )
    )

    return isLocalhost
      ? `${window.location.protocol}//${window.location.host}/checklist/${item.key}`
      : `${process.env.REACT_APP_SITE_ENDPOINT}/checklist/${item.key}`
  }

  const handleZoomIn = () => {
    setZoomLevel(zoomLevel + 1 <= 8 ? zoomLevel + 1 : zoomLevel)
  }

  const handleZoomOut = () => {
    setZoomLevel(zoomLevel - 1 >= -3 ? zoomLevel - 1 : zoomLevel)
  }

  return (
    <IonPage className="page-checklist">
      <IonHeader className="no-border">
        <IonToolbar color="primary">
          <IonTitle>
            <IonButton class="logo immutable">
              {t('Suiqui Checklist')}
            </IonButton>
            <span>{t('Resume')}</span>
          </IonTitle>
          <IonButtons slot="end">
            <IonButton
              color="light"
              fill="clear"
              onClick={() => handleSwitchQrCode()}
            >
              <IonIcon slot="icon-only" icon={qrCode} />
            </IonButton>
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonContent
        ref={contentRef}
        scrollEvents={true}
        style={{
          zoom: 1 * (zoomLevel * 0.1 + 1),
        }}
      >
        <IonRefresher slot="fixed" onIonRefresh={doRefresh}>
          <IonRefresherContent></IonRefresherContent>
        </IonRefresher>

        <IonItemDivider color="light" sticky={true}>
          <IonLabel className="ion-text-wrap">
            <h2>{checklistPub?.print_name || checklistPub?.name || '···'}</h2>
          </IonLabel>
          {isPlatform('desktop') && (
            <IonButtons slot="end">
              <IonButton
                color="medium"
                slot="icon-only"
                onClick={handleZoomOut}
              >
                <IonIcon icon={removeCircleOutline}></IonIcon>
              </IonButton>
              <IonButton color="medium" slot="icon-only" onClick={handleZoomIn}>
                <IonIcon icon={addCircleOutline}></IonIcon>
              </IonButton>
            </IonButtons>
          )}
        </IonItemDivider>

        <div
          hidden={!showQRCode}
          style={{
            width: '100%',
            height: '100%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <IonGrid
            className="centered"
            style={{
              paddingTop: '50px',
              width: '100%',
            }}
          >
            <IonRow className="ion-justify-content-center">
              <QRCode
                value={window.location.href}
                size={
                  isPlatform('mobile')
                    ? 256
                    : width > height
                    ? height * 0.5
                    : width * 0.5
                }
              />
            </IonRow>
            <IonRow className="ion-justify-content-center">
              <IonItem lines="none" className="ion-text-wrap ion-text-center">
                <h2>{checklistPub?.print_name || checklistPub?.name}</h2>
              </IonItem>
            </IonRow>
          </IonGrid>
        </div>

        <IonItemDivider>
          {setShowCopyUrlToast && (
            <CopyToClipboard text={getUrl(match.params)} onCopy={() => {}}>
              <IonButton
                color="medium"
                onClick={e => {
                  e.preventDefault()
                  e.stopPropagation()
                  setShowCopyUrlToast(true)
                }}
              >
                <IonIcon slot="start" icon={share} />
                <IonLabel>{t('Copy url')}</IonLabel>
              </IonButton>
            </CopyToClipboard>
          )}
          {checklistPub?.serial && (
            <IonLabel slot="end" className="ion-text-wrap ion-padding-end">
              {t('Serial=')}
              {checklistPub.serial}
            </IonLabel>
          )}
        </IonItemDivider>

        {isPageLoading ? (
          <div className="ion-text-center centered">
            <IonSpinner color="primary" name="crescent"></IonSpinner>
          </div>
        ) : (
          <IonList>
            {checklistPub?.error && (
              <IonText color="danger" className="ion-text-center">
                <h2>{t('Please enter correct checklist key.')}</h2>
              </IonText>
            )}

            <IonList>
              {checklistPub?.due_date && (
                <IonRow key={-1}>
                  <IonCol>
                    <IonItem>
                      <IonLabel color="medium" className="ion-text-wrap">
                        {t('Due Date')}
                      </IonLabel>
                      <IonLabel className="ion-text-right ion-text-wrap">
                        {checklistPub?.due_date}
                        {checklistPub?.due_date_ext && (
                          <> ～ {checklistPub?.due_date_ext}</>
                        )}
                      </IonLabel>
                    </IonItem>
                  </IonCol>
                </IonRow>
              )}
              {checklistPub?.custom_fields?.map((field: any, index: number) => {
                if (Array.isArray(field)) {
                  return (
                    <IonRow key={index}>
                      {field?.map((item: any) => (
                        <IonCol>
                          <IonItem>
                            <IonLabel color="medium" className="ion-text-wrap">
                              {item.key}
                            </IonLabel>
                            {/* 高亮顯示已被代入環境變數的表頭欄位 */}
                            <IonLabel
                              className={classNames(
                                'ion-text-right',
                                'ion-text-wrap',
                                { 'evaluated': item.eval_value !== item.value }
                              )}
                            >
                              {item.eval_value}
                            </IonLabel>
                          </IonItem>
                        </IonCol>
                      ))}
                    </IonRow>
                  )
                } else {
                  return (
                    <IonRow key={index}>
                      <IonCol>
                        <IonItem>
                          <IonLabel color="medium" className="ion-text-wrap">
                            {field.key}
                          </IonLabel>
                          {/* 高亮顯示已被代入環境變數的表頭欄位 */}
                          <IonLabel
                            className={classNames(
                              'ion-text-right',
                              'ion-text-wrap',
                              { 'evaluated': field.eval_value !== field.value }
                            )}
                          >
                            {field.eval_value}
                          </IonLabel>
                        </IonItem>
                      </IonCol>
                    </IonRow>
                  )
                }
              })}
            </IonList>

            {checklistPub?.children?.map((child: IChecklist, index: number) => {
              if (child?.children?.length) {
                return (
                  <>
                    <IonCardHeader color="light">
                      <IonLabel>{child.name}</IonLabel>
                    </IonCardHeader>
                    {child?.children?.map((item: IChecklist, index: number) => {
                      return (
                        <IonCard
                          key={index}
                          className={getStatusColor(item.status)}
                        >
                          <IonCardHeader>
                            <IonCardTitle>
                              <IonChip color={getStatusColor(item.status)}>
                                {t(`Status ${item.status}`)}
                              </IonChip>
                              {item.name}
                            </IonCardTitle>
                          </IonCardHeader>
                          {item.standard && (
                            <IonItem lines="none">
                              <IonLabel
                                color="medium"
                                className="ion-text-wrap"
                              >
                                {t('Checklist Standard=')}
                              </IonLabel>
                              <IonLabel className="ion-text-wrap">
                                {item.standard}
                              </IonLabel>
                            </IonItem>
                          )}

                          {item.check_result && (
                            <IonItem lines="none">
                              <IonLabel
                                color="medium"
                                className={classNames({
                                  'ion-text-wrap': true,
                                  [`${getStatusColor(item.status)}`]: true,
                                })}
                              >
                                {t('Checklist Result=')}
                              </IonLabel>
                              <IonLabel
                                className={classNames({
                                  'ion-text-wrap': true,
                                  [`${getStatusColor(item.status)}`]: true,
                                })}
                              >
                                {item.check_result}
                              </IonLabel>
                            </IonItem>
                          )}
                          {item?.files && (
                            <div className="photo-slider">
                              <Carousel
                                centerMode
                                centerSlidePercentage={
                                  item.files.length === 1
                                    ? 100
                                    : isPlatform('mobile')
                                    ? 50
                                    : 25
                                }
                                emulateTouch
                                useKeyboardArrows={!showPhotoViewerModal}
                                showThumbs={false}
                                statusFormatter={statusFormatter}
                                showIndicators={false}
                              >
                                {item.files.map(
                                  (file: ISuiquiFile, index: number) => {
                                    return (
                                      <div
                                        className="openable"
                                        onClick={event =>
                                          openPhotoViewerModal(
                                            event,
                                            index,
                                            item.files
                                          )
                                        }
                                      >
                                        <img src={`${file.url}=h240`}></img>
                                        {file?.memo && (
                                          <p className="legend">{file.memo}</p>
                                        )}
                                      </div>
                                    )
                                  }
                                )}
                              </Carousel>
                            </div>
                          )}
                        </IonCard>
                      )
                    })}
                  </>
                )
              } else {
                return (
                  <IonCard key={index} className={getStatusColor(child.status)}>
                    <IonCardHeader>
                      <IonCardTitle>
                        <IonChip color={getStatusColor(child.status)}>
                          {t(`Status ${child.status}`)}
                        </IonChip>
                        {child.name}
                      </IonCardTitle>
                    </IonCardHeader>
                    {child.standard && (
                      <IonItem lines="none">
                        <IonLabel color="medium" className="ion-text-wrap">
                          {t('Checklist Standard=')}
                        </IonLabel>
                        <IonLabel className="ion-text-wrap">
                          {child.standard}
                        </IonLabel>
                      </IonItem>
                    )}

                    {child.check_result && (
                      <IonItem lines="none">
                        <IonLabel
                          color="medium"
                          className={classNames({
                            'ion-text-wrap': true,
                            [`${getStatusColor(child.status)}`]: true,
                          })}
                        >
                          {t('Checklist Result=')}
                        </IonLabel>
                        <IonLabel
                          className={classNames({
                            'ion-text-wrap': true,
                            [`${getStatusColor(child.status)}`]: true,
                          })}
                        >
                          {child.check_result}
                        </IonLabel>
                      </IonItem>
                    )}
                    {child?.files && (
                      <div className="photo-slider">
                        <Carousel
                          centerMode
                          centerSlidePercentage={
                            child.files.length === 1
                              ? 100
                              : isPlatform('mobile')
                              ? 50
                              : 25
                          }
                          emulateTouch
                          useKeyboardArrows={!showPhotoViewerModal}
                          showThumbs={false}
                          statusFormatter={statusFormatter}
                          showIndicators={false}
                        >
                          {child.files.map(
                            (file: ISuiquiFile, index: number) => {
                              return (
                                <div
                                  className="openable"
                                  onClick={event =>
                                    openPhotoViewerModal(
                                      event,
                                      index,
                                      child.files
                                    )
                                  }
                                >
                                  <img src={`${file.url}=h240`}></img>
                                  {file?.memo && (
                                    <p className="legend">{file.memo}</p>
                                  )}
                                </div>
                              )
                            }
                          )}
                        </Carousel>
                      </div>
                    )}
                  </IonCard>
                )
              }
            })}
            {checklistPub?.key && !checklistPub?.error && (
              <div className="ion-text-center">
                <IonButton
                  color="warning"
                  fill="clear"
                  size="large"
                  slot="icon-only"
                  onClick={scrollToTop}
                >
                  <IonIcon icon={caretUp} />
                </IonButton>
              </div>
            )}
          </IonList>
        )}

        <IonFooter className="ion-text-center">
          <IonButton
            fill="clear"
            size="small"
            strong={true}
            className="immutable"
          >
            <IonLabel color="medium">
              {t('Suiqui Resume')} {`${process.env.REACT_APP_VERSION}`}
            </IonLabel>
          </IonButton>
        </IonFooter>
      </IonContent>
      <IonLoading
        isOpen={showLoading}
        onDidDismiss={() => setShowLoading(false)}
        spinner="crescent"
      />

      <IonModal
        isOpen={showPhotoViewerModal}
        showBackdrop={false}
        backdropDismiss={false}
        cssClass="modal-fullscreen"
      >
        {showPhotoViewerModal && (
          <PhotoViewerModal
            isOpen={showPhotoViewerModal}
            item={currentItem}
            items={currentItems}
            index={currentIndex}
            dismiss={dismissPhotoViewerModal}
          />
        )}
      </IonModal>
      <IonModal
        isOpen={showPanoramaViewerModal}
        showBackdrop={false}
        backdropDismiss={false}
        cssClass="modal-fullscreen"
      >
        {showPanoramaViewerModal && (
          <PanoramaViewerModal
            isOpen={showPanoramaViewerModal}
            item={currentItem}
            items={currentItems}
            index={currentIndex}
            dismiss={dismissPanoramaViewerModal}
          />
        )}
      </IonModal>

      <IonToast
        isOpen={showCopyUrlToast}
        onDidDismiss={() => setShowCopyUrlToast(false)}
        message={t('The link is copied to clipboard.')}
        duration={3000}
      />
    </IonPage>
  )
}

export default Checklist
