import localforage from 'localforage'
import { useCallback, useEffect, useState } from 'react'

export const useClientStorage = (key: string, defaultValue?: any) => {
  const [isLoading, setIsLoading] = useState(true)
  const [data, setData] = useState()
  const [err, setErr] = useState(null)

  const writeData = useCallback(
    async data => {
      await localforage.setItem(key, JSON.stringify(data))
    },
    [key]
  )

  const readData = useCallback(async () => {
    try {
      const serialized: string = await localforage.getItem(key)
      const parsed = JSON.parse(serialized)
      if (Object.is(parsed, null)) {
        setData(defaultValue)
      } else {
        setData(parsed)
      }
      setErr(null)
    } catch (error) {
      setErr(error)
    }
    setIsLoading(false)
  }, [setIsLoading, key, defaultValue])

  useEffect(() => {
    writeData(data)
  }, [data, writeData])
  useEffect(() => {
    readData()
  }, [readData])
  return [data, setData, isLoading, err]
}
