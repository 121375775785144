import { isPlatform } from '@ionic/core'
import { IonApp, IonPage, setupConfig } from '@ionic/react'
import { IonReactRouter } from '@ionic/react-router'
/* Core CSS required for Ionic components to work properly */
import '@ionic/react/css/core.css'
import '@ionic/react/css/display.css'
import '@ionic/react/css/flex-utils.css'
import '@ionic/react/css/float-elements.css'
/* Basic CSS for apps built with Ionic */
import '@ionic/react/css/normalize.css'
/* Optional CSS utils that can be commented out */
import '@ionic/react/css/padding.css'
import '@ionic/react/css/structure.css'
import '@ionic/react/css/text-alignment.css'
import '@ionic/react/css/text-transformation.css'
import '@ionic/react/css/typography.css'
import { initializeFileTypeIcons } from '@uifabric/file-type-icons'
import React, { Suspense } from 'react'
import { I18nextProvider } from 'react-i18next'
import AppRoutes from './AppRoutes'
import { detectUserAgent } from './helpers/util'
import i18n from './i18n'
/* Theme variables */
import './theme/variables.css'

if (isPlatform('ios')) {
  window.addEventListener('orientationchange', () => {
    if (window.orientation == 0 || window.orientation == 180) {
      setTimeout(() => {
        window.scrollTo(0, 1)
      }, 350)
    }
  })
}

detectUserAgent()
initializeFileTypeIcons()
setupConfig({
  mode: 'md',
  swipeBackEnabled: false,
})

const App: React.FC = () => (
  <Suspense fallback={<p>Loading</p>}>
    <I18nextProvider i18n={i18n}>
      <IonApp>
        <IonReactRouter>
          <IonPage id="main">
            <AppRoutes></AppRoutes>
          </IonPage>
        </IonReactRouter>
      </IonApp>
    </I18nextProvider>
  </Suspense>
)

export default App
