import { combineReducers } from 'redux'
import { StateType } from 'typesafe-actions'
import { authReducer as auth } from '../epics/auth'
import { checklistPubReducer as checklistPub } from '../epics/checklist-pub'
import { listOfChecklistPubReducer as listOfChecklistPub} from '../epics/list-of-checklist-pub'
import { listOfTeamsOfCompanyPubReducer as listOfTeamsOfCompanyPub } from '../epics/list-of-teams-of-a-company-pub'
import authDuck from './auth'

/**
 * Redux root 狀態樹
 * Notice: 
 * 能用瀏覽器 devtools 的 redux 分頁(redux dev tools with extension) 觀看
 */
const rootReducer = combineReducers({
  auth,
  authDuck,
  checklistPub,
  listOfChecklistPub,
  listOfTeamsOfCompanyPub,
})

export type RootState = StateType<typeof rootReducer>

export default rootReducer
